<template>
  <div id="starts">
    <div class="title">
      <img src="../../img/板块图标 - 副本_slices/icon.png" alt="" />
      <span>班级列表</span>
    </div>
    <div class="main">
      <div class="addbtn">
        <div class="btn" @click="dialogTableVisible = true">添加班级</div>
      </div>
      <table>
        <tr>
          <th>序号</th>
          <th>班级名称</th>
          <th>班级人数</th>
          <th>操作</th>
        </tr>
        <tr v-for="(item, index) in classList" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ item.class_name }}</td>
          <td>{{ item.class_count }}</td>
          <td>
            <span
              @click="
                dialogTableVisible = true;
                bj(item.id, index);
              "
              >编辑</span
            >
            <span @click="delclass(item.id)">删除</span>
          </td>
        </tr>
      </table>
    </div>
    <el-dialog title="添加班级" class="dia" :visible.sync="dialogTableVisible">
      <div class="box">
        <el-input
          v-model="classname"
          class="name"
          placeholder="请输入班级名称"
        ></el-input>
        <el-input
          v-model="classnum"
          placeholder="请输入班级人数"
          type="text"
          oninput="value=value.replace(/[^\d]/g,'')"
        ></el-input>

        <div class="addbtn">
          <div class="btn" v-if="this.change == 1" @click="addclass()">
            添加班级
          </div>
          <div class="btn" v-else @click="changeclass()">确认修改</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as util from "@/unit/network";
export default {
  data() {
    return {
      dialogTableVisible: false,
      classname: "",
      classnum: "",
      classList: [],
      id: "",
      change: 1,
    };
  },
  mounted() {
    this.getclassList();
  },
  methods: {
    getclassList() {
      util.post("/classList", {}, (res) => {
        // console.log(res);
        this.classList = res.data;
      });
    },
    addclass() {
      this.change = 1;
      util.post(
        "/classAdd",
        {
          class_name: this.classname,
          class_count: this.classnum,
        },
        (res) => {
          console.log(res);
          this.dialogTableVisible = false;
          util.post("/classList", {}, (res) => {
            this.classList = res.data;
          });
          this.classname = this.classnum = "";
        }
      );
    },
    bj(id, i) {
      this.change = 2;
      this.classname = this.classList[i].class_name;
      this.classnum = this.classList[i].class_count;
      this.id = id;
    },
    changeclass() {
      util.post(
        "/classEdit",
        {
          id: this.id,
          class_name: this.classname,
          class_count: this.classnum,
        },
        (res) => {
          console.log(res);
          this.dialogTableVisible = false;
          util.post("/classList", {}, (res) => {
            // console.log(res);
            this.classList = res.data;
          });
        }
      );
    },
    delclass(id) {
      util.post("/classDel", { id: id }, (res) => {
        console.log(res);
        util.post("/classList", {}, (res) => {
          this.classList = res.data;
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
#starts {
  height: 100%;
  .title {
    height: 73px;
    padding: 0 14px;
    display: flex;
    align-items: center;
    background-color: #eff0f2;
    img {
      margin-right: 8px;
      width: 18px;
    }
    span {
      font-size: 20px;
      font-weight: bold;
      color: #131313;
    }
  }
  .main {
    height: calc(100% - 73px);
    background-color: #fff;
    padding: 32px 211px 0 183px;
    .addbtn {
      display: flex;
      justify-content: flex-end;
    }
    .btn {
      width: 120px;
      height: 36px;
      background: #1c5eff;
      border-radius: 5px;
      color: #fff;
      margin-bottom: 23px;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      border: 0.01rem solid #bebec0;
      font-size: 16px;
      th {
        border-collapse: collapse;
        border: 0.01rem solid #bebec0;
        height: 60px;
        background-color: #f5f6f8;
        font-weight: 400;
      }
      td {
        border-collapse: collapse;
        border: 0.01rem solid #bebec0;
        height: 96px;
        text-align: center;
        span {
          margin: 0 3px;
          cursor: pointer;
        }
      }
    }
  }
  .dia {
    /deep/.el-dialog {
      width: 394px !important;
      height: 414px;
      margin-top: 26vh !important;
    }
    /deep/.el-dialog__header {
      text-align: center;
      color: #2c2c2c;
      font-weight: bold;
    }
    /deep/.el-dialog__body {
      padding: 30px 70px !important;
    }
    .box {
      .name {
        margin-bottom: 30px;
      }
      .addbtn {
        display: flex;
        justify-content: center;
        margin-top: 50px;
      }
      .btn {
        width: 120px;
        height: 36px;
        background: #1c5eff;
        border-radius: 5px;
        color: #fff;
        margin-bottom: 23px;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>